/* Add this to your existing CSS file */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
       backdrop-filter: blur(5px); /* Apply blur to the background */
    animation: fadeIn 0.3s ease-in-out; /* Add fade-in animation */
  }
  
  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-text {
    margin-top: 10px;
    color: white;
    font-weight: bold;
  }

.warning-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Remove background dimming: */
    /* background-color: rgba(0, 0, 0, 0.5);  */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px); /* Apply blur to the background */
    animation: fadeIn 0.3s ease-in-out; /* Add fade-in animation */
    }
    
/* Apply blur to the background */
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
  
  .warning-content {
    background-color: #fff;
    padding: 30px; 
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-family: "Inter", sans-serif; 
    font-weight: 500;
  }
  
  .warning-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px; 
  }
  
  .warning-buttons button {
    background-color: #F16736; 
    color: #fff;
    border-radius: 20px;
    font-size: 14px;
    padding: 10px 20px; 
    border: none;
  }
  
  .warning-buttons button:hover {
    background-color: #c05a35; 
    color: #fff;
  }

  .floating-error {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #ffe6e6;
    color: red;
    padding: 10px 20px;
    border: 1px solid red;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-family: "Inter", sans-serif;
  }
  
  .close-error-btn {
    border: none;
    background: none;
    font-size: 18px;
    color: red;
    cursor: pointer;
    margin-left: 15px;
  }
  
  .close-error-btn:hover {
    color: darkred;
  }

.entryitems{
    font-family: "Inter", sans-serif;
    font-weight: 500;
    background-color: transparent;
}
.form-container {
    padding: 30px;
    border-radius: 10px;
}
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.form-group input[type="text"] {
    flex: 1;
    margin-right: 10px;
}
.form-group button {
    flex-shrink: 0;
}
.btn-submit, .btn-next, .btn-clear {
    margin-right: 10px;
}
.nav-tabs{
    /* background-color: #FBF4ED; */
    margin-top: 20px;
    text-decoration: none;
}
.nav-link{
    text-decoration: none !important;
    color: black;
    font-size: 13.5px;
}
.nav-link:hover{
    text-decoration: none !important;
    color: #F16736;
}

.btn-add{
    background-color: #F16736;
    color: #fff;
    border-radius: 20px;
    font-size: 14px;
}

.btn-add:hover{
    background-color: #c05a35;
    color: #fff;
}

.submit-btn-last{
    background-color: #0e146d;
    color: #fff;
    border-radius: 20px;
    font-size: 14px;
    margin-bottom: 50px;
}

.submit-btn-last:hover{
    background-color: #4a51ac;
    color: #fff;
}

.form-control{
    border-radius: 20px;
}

.btnEditDelete{
    color: #615756;
    background-color: #E9ECEF;
    width: 40px;
}

.btnEditDelete:hover{
    color: #F16736 ;
    background-color: #FBF4ED;
}