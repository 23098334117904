.funding-advisor-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    margin-top: 20px;

  }
  
  .form-control {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .error-message {
    color: #dc3545;
    margin-top: 10px;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  /* .form-group{

  } */
  .form-group label {
    display: block;
    margin-bottom: 5px;
    
  }
  
  .form-group input[type="radio"],
  .form-group input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .form-group > div {
    display: flex;
    flex-wrap: wrap;
  }
  
  .form-group > div > label {
    margin-right: 15px;
    margin-bottom: 5px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
  }

  .label-funding {
    font-weight: 550;
    display: block;
    margin-bottom: 5px;
  }

  .input-row {
    display: flex;
    gap: 10px;
  }
  
  .input-row .form-control {
    flex: 1;
  }
  
  .error-message {
    color: #dc3545;
    margin-top: 5px;
    font-size: 0.9em;
  }