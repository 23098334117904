/* General Styles */
.App {
  font-family: Arial, sans-serif;
}

.contain-panel {
  display: flex;
  height: 100vh;
}

.left-panel, .right-panel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.left-panel {
  background-color: #FF9776;
}

.signup-form {
  background: white;
  padding: 40px;
  width: 450px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-form h2 {
  margin-bottom: 20px;
}

.signup-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.signup-button {
  background-color: #FF6F3F;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.signup-button:hover {
  background-color: #E65A2B;
}

.signup-form p {
  margin-top: 10px;
}

.signup-form a {
  color: red;
}

.right-panel {
  background-color: white;
  text-align: center;
}

.right-panel h2 {
  margin-bottom: 20px;
}

.login-button {
  background-color: #FF6F3F;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #E65A2B;
}

.illustration {
  margin-top: 20px;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container input {
  width: 100%;
  padding-right: 40px; /* Make room for the eye icon */
}

.show-password-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}


/* Password Strength */

.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container input {
  width: 100%;
  padding-right: 70px; /* Make room for both eye icon and info icon */
}

.show-password-button {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-info-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #75758B;
}

.password-requirements-tooltip {
  position: fixed; /* Change this to fixed for positioning relative to the viewport */
  right: 0; /* Adjust the distance from the right edge of the viewport */
  top: 20px;   /* Adjust the distance from the top of the viewport */
  width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}


.password-requirements-tooltip p {
  margin: 5px 0;
  font-size: 12px;
  color: #75758B;
}

.password-requirements-tooltip p.met {
  color: #4CAF50;
}

.strength-bars {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.strength-bar {
  height: 4px;
  flex-grow: 1;
  background-color: #e0e0e0;
  margin-right: 2px;
  transition: background-color 0.3s ease;
}

.strength-bar:last-child {
  margin-right: 0;
}

.strength-bar.filled {
  background-color: #FF6F3F;
}

.strength-text {
  font-size: 12px;
  color: #75758B;
  text-align: right;
}
@media (max-width: 480px) {
  .signup-form {
    padding: 15px;
    width: 90%;
  }

  .signup-form h5 {
    font-size: 1.5rem;
  }

  .signup-form input {
    padding: 8px;
  }

  .signup-button {
    padding: 8px;
  }

  .login-button {
    width: 90%;
    padding: 8px;
  }

  .right-panel img {
    width: 200px;
  }
}
