.chatbot-button {
  width: 50px;
  height: 50px;
  font-size: 19px;
  position: fixed;
  z-index: 1000;
  bottom: 200px;
  right: 20px;
  background-color: #F16635;
  color: #fff;
}

.chatbot-button:hover, .chatbot-button:focus, .chatbot-button:active {
  background-color: #F16635;
  color: #fff;
}
.chat-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chat-message {
  display: block;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 20px;
  max-width: 80%;
  word-wrap: break-word;
  font-size: 15px;
}

.chat-message.user {
  background-color: #F16635;
  color: white;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #e9ecef;
  color: black;
  align-self: flex-start;
}

.btn-custom-orange {
  color: white;
  background-color: #F16635;
  border-color: #F16635;
}

.btn-custom-orange:hover, .btn-custom-orange:focus, .btn-custom-orange:active {
  color: white;
  background-color: #d55a2b;
  border-color: #d55a2b;
}
 
.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: bounce 1.3s linear infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -1.1s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes bounce {
  0%, 60%, 100% { transform: translateY(0); }
  30% { transform: translateY(-10px); }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}