.table-card{
    padding: 20px;
}

.card {
    outline: none;
    border-style: dashed;
    background-color: #FBF4ED;
    margin-top: 20px;
    margin-bottom: 30px;
}

.tableContainer{
    padding: 30px;
    margin-bottom: 30px;
}

.highlight-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.95rem;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #3b3b3b;
}
.highlight-item:last-child {
    margin-bottom: 0;
}
.highlight-item .icon {
    margin-left: 0.5rem;
}

.card .card-body {
    padding: 20px;
}
.card-header {
    border-bottom: none;
}
.card-header h4 {
    margin-bottom: 0;
}

.card-subtitle{
    font-size: 14px;
}
.card-header, .card-body {
    border: none;
    padding: 1.5rem;
    
}
.card-header{
    background-color: #F16635 !important;
    /* background-color: #636363 !important; */
    color: #fff;
}