
/* Navbar */
.navbar-custom {
    background-color: #ffffff;
    margin-left: 250px;
    width: calc(100% - 250px);
    /* position: sticky !important; */
    top: 0;
    z-index: 1000;

}
.navbar-custom .navbar-nav .nav-link {
    color: rgba(0,0,0,.5);
}
.navbar-custom .navbar-nav .nav-link:hover {
    color: rgba(0,0,0,.7);
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.5);
}
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0,0,0,.7);
}
.navbar-brand img {
    height: 40px;
}

.navbar-text{
    font-weight: 500;
}
.navbar-toggler{
    outline: none;
    border: none !important;
}
.navbar-toggler:focus{
    outline: none !important;
    border: none !important;
}


/* profile section*/
.profile-card {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 500;
    background-color: transparent;
    width: 250px;
}

.profile-card:hover{
    background-color: transparent;
    outline: none;
    border: none !important;
}

.profile-card{
    background-color: transparent;
    outline: none;
    border: none !important;
}
.profile-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.5rem;
}
.profile-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.profile-details {
    display: flex;
    flex-direction: column;
}
.profile-details h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}
.profile-details p {
    margin: 0;
    color: #666;
    font-size: 0.875rem;
}
#dropdown{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-left: 50px;
    margin-top: 10px;
    outline: none;
    border: none;
    transition: 0.5s ease-in-out;
}
#dropdown-item:hover{
    color: #F16635;
}
.dropdown-menu{
 outline: none;
 border: none !important;
 margin-top: 10px;
}
#search-bar{
    border-radius: 50px;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #f3f3f3;
}
.dashboard-cards .card{
    outline: none;
    border-style: dashed;
}