.contain-panel {
  display: flex;
  height: 100vh;
}

.left-panel, .right-panel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.left-panel {
  background-color: #FF9776;
}

.login-form {
  background: white;
  padding: 40px;
  width: 450px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.login-form h5 {
  margin-bottom: 20px;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.show-password-button {
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.login-button {
  background-color: #FF6F3F;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #E65A2B;
}

.login-form p {
  margin-top: 10px;
}

.login-form a {
  color: red;
}

.right-panel {
  background-color: white;
  text-align: center;
}

.signup-button {
  background-color: #FF6F3F;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.signup-button:hover {
  background-color: #E65A2B;
}

.illustration {
  margin-top: 20px;
}

.illustration img {
  width: 100%;
  max-width: 400px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .contain-panel {
    flex-direction: column-reverse; /* Swapping the order of panels */
  }

  .left-panel, .right-panel {
    width: 100%;
    padding: 20px;
    flex: none;
    justify-content: center;
    align-items: center;
  }

  .login-form {
    width: 100%; /* Full width on mobile */
    padding: 20px;
    margin: 20px 0;
  }

  .right-panel img {
    width: 250px;
    padding-bottom: 20px;
  }

  .login-button, .signup-button {
    width: 80%;
  }

  .illustration img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .login-form {
    padding: 15px;
    width: 90%;
  }

  .login-form h5 {
    font-size: 1.5rem;
  }

  .login-form input {
    padding: 8px;
  }

  .login-button, .signup-button {
    padding: 8px;
  }

  .right-panel img {
    width: 200px;
  }
}
